<template>
  <div class="df-performance" v-loading="loading">
    <div class="df-row">
      <!-- start 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>
      <!-- end 日期选择器 -->

      <!-- start 部门下拉菜单 -->
      <div class="df-col">
        <el-select
          v-model="submitData.dept_guid"
          placeholder="请选择部门"
          size="mini"
        >
          <el-option
            v-for="item in deptList"
            :key="item.DeptGuid"
            :label="item.DeptName"
            :value="item.DeptGuid"
          >
          </el-option>
        </el-select>
      </div>
      <!-- end 部门下拉菜单 -->

      <!-- start 按钮区域 -->
      <div class="df-col">
        <!-- start 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
        <!-- end 查询按钮 -->

        <!-- start 导出表格按钮 -->
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button>
        <!-- end 导出表格按钮 -->
      </div>
      <!-- start 按钮区域 -->
    </div>

    <!-- start 统计表整合，由于SheetJS的xlsx文档没有写清楚如何把多个表格整合成一个工作表并导出表格，
    所以最外层只能用一个大的table包裹所有的统计表，通过获取最外层table的dom节点来生成一个文件的方式导出xlsx表格 -->
    <table id="exprot-table" class="table-box">
      <!-- start 这个应该是店内客户来源统计 -->
      <table class="df-table" bordercolor="#E3E3E3" border="1">
        <tbody>
          <tr>
            <td>
              指定客数(比例):
              {{ (workSummaryData.AssignBillCountRate * 100) | toFixed }}%
            </td>
            <td>
              指定业绩(比例)：{{
                (workSummaryData.AssignPerforRate * 100) | toFixed
              }}%
            </td>
            <td>
              非指定客数(比例)：{{
                (workSummaryData.NotAssignBillCountRate * 100) | toFixed
              }}%
            </td>
            <td>
              非指定业绩(比例)：{{
                (workSummaryData.NotAssignPerforRate * 100) | toFixed
              }}%
            </td>
            <td>会员客数: {{ workSummaryData.MemberPerson }}</td>
            <td rowspan="2">总客数：{{ workSummaryData.TotalBillCount }}</td>
            <td rowspan="2">客单价：{{ workSummaryData.AvgPrice }}</td>
          </tr>
          <tr style="padding: 10px">
            <td>
              男客数(比例)：{{
                (workSummaryData.MaleBillCountRate * 100) | toFixed
              }}%
            </td>
            <td>
              男客业绩(比例)：{{
                (workSummaryData.MalePerforRate * 100) | toFixed
              }}%
            </td>
            <td>
              女客数(比例)：{{
                (workSummaryData.FemaleBillCountRate * 100) | toFixed
              }}%
            </td>
            <td>
              女客业绩(比例)：{{
                (workSummaryData.FemalePerforRate * 100) | toFixed
              }}%
            </td>
            <td>散客客数：{{ workSummaryData.NotMemberPerson }}</td>
          </tr>
        </tbody>
      </table>
      <!-- end 店内客户来源统计 -->

      <!-- 空行占位，table标签里块级元素都无法使最后导出的表格空行，只能用tr强制空行 -->
      <tr></tr>

      <!-- start 店内业绩统计表 -->
      <el-table
        class="custom-header"
        :data="tableData"
        border
        stripe
        :span-method="objectSpanMethod"
      >
        <el-table-column width="200" label="业绩类别">
          <template slot-scope="scope">
            <div v-if="scope.row.Name">
              {{ scope.row.Name }}
            </div>
            <div v-else>
              <!-- 当没有业绩类别名称时,当前行应要输出支付信息,而业绩类别这一列不输出任何字符 -->
            </div>
          </template>
        </el-table-column>

        <el-table-column label="名称">
          <!-- 名称列需要判断应要输出项目名称还是支付信息，但却不能分开两个列做判断，因为在两个el-table-column
          标签做v-if，如果这个表同时存在两种判断都有的列内容，表格会直接崩溃原因是porp报错，即数据绑定错误，
          所以需要在一个el-table-column列里面做v-if -->
          <template slot-scope="scope">
            <div v-if="scope.row.ItemName">
              {{ scope.row.ItemName }}
            </div>

            <div
              v-else-if="scope.row.Name && !scope.row.ItemName"
              class="notData"
            >
              暂无数据
            </div>

            <div v-else>
              <span
                class="highlight"
                v-if="payItem.PayMoney > 0"
                v-for="(payItem, payIndex) in scope.row.PaymentList"
                :key="payIndex"
                v-html="
                  payItem.PaymentName + ': ¥ ' + payItem.PayMoney + ', &nbsp;'
                "
              >
              </span>
              <span
                class="lightskyblue"
                v-html="
                  scope.row.OpenKeepDonaMoney > 0
                    ? '&nbsp;赠送卡金: ¥ ' + scope.row.OpenKeepDonaMoney
                    : ''
                "
              >
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="ItemCount"> </el-table-column>
        <el-table-column label="业绩" prop="Perfor"> </el-table-column>
        <el-table-column label="指定项目数" prop="AssignItemCount">
        </el-table-column>
        <el-table-column label="非指定项目数" prop="NotAssignItemCount">
        </el-table-column>
        <el-table-column label="总完成个数">
          <template slot-scope="scope">
            {{ scope.row.ItemName ? scope.row.TotalCount : "" }}
          </template>
        </el-table-column>
        <el-table-column label="总业绩">
          <template slot-scope="scope">
            {{ scope.row.ItemName ? scope.row.TotalPerfor : "" }}
          </template>
        </el-table-column>
      </el-table>
      <!-- end 店内业绩统计表 -->

      <tr></tr>

      <!--  -->
      <!-- <el-table
        class="big-table"
        v-for="(item, index) in tableData"
        :key="index"
        :data="[{ id: 0 }, { id: 1 }]"
        border
        stripe
        size="mini"
        :show-header="false"
        style="width: 100%"
      >
        <el-table-column label="业绩类别" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.id == 0">
              {{ item.Name }}
            </div>
            <div v-if="scope.row.id == 1"></div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div v-if="scope.row.id == 0">
              <el-table
                :data="item.ItemList"
                :show-header="false"
                size="mini"
                class="custom-table"
                :span-method="objectSpanMethod"
              >
                <el-table-column prop="ItemName" label="名称">
                </el-table-column>
                <el-table-column prop="ItemCount" label="数量">
                </el-table-column>
                <el-table-column prop="Perfor" label="业绩"> </el-table-column>
                <el-table-column prop="AssignItemCount" label="指定项目数">
                </el-table-column>
                <el-table-column prop="NotAssignItemCount" label="非指定项目数">
                </el-table-column>
                <el-table-column label="总完成个数">
                  <template slot-scope="scope">
                    {{ item.TotalCount }}
                  </template>
                </el-table-column>
                <el-table-column label="总业绩">
                  <template slot-scope="scope">
                    {{ item.TotalPerfor }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="scope.row.id == 1">
              <span
                class="highlight"
                v-if="payItem.PayMoney > 0"
                v-for="(payItem, payIndex) in item.PaymentList"
                :key="payIndex"
                v-html="
                  payItem.PaymentName + ': ¥ ' + payItem.PayMoney + ', &nbsp;'
                "
              >
              </span>
              <span
                class="lightskyblue"
                v-html="
                  item.OpenKeepDonaMoney > 0
                    ? '&nbsp;赠送卡金: ¥ ' + item.OpenKeepDonaMoney
                    : ''
                "
              >
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <tr></tr> -->

      <!--  -->
      <el-table
        :data="[{ id: 1 }]"
        border
        stripe
        style="width: 100%; margin-top: 20px"
        size="mini"
      >
        <el-table-column prop="date" label="总业绩">
          <el-table-column
            :label="item.Name"
            v-for="(item, index) in totalStatisData.NotCardPayItems"
            :key="index"
          >
            <template slot-scope="scope">
              {{ item.Value }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="date" label="会员划卡业绩及划卡人次">
          <el-table-column
            v-for="(item, index) in totalStatisData.CardPayItems"
            :key="index"
            :class-name="item.Name == '人次' ? 'green' : ''"
            :label="item.Name"
          >
            <span>{{ item.Value }}</span>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column
			    prop="date"
			    label="扣除信息费">
					<el-table-column
						prop="name"
						label="金额">
					</el-table-column>
					<el-table-column
						prop="name"
						label="人次">
					</el-table-column>
			 </el-table-column>
			 <el-table-column
			    prop="date"
			    label="失效套餐 转换利润"
			    width="90">
			 </el-table-column> -->
      </el-table>

      <!--  -->
      <div class="table-tail">
        <el-table
          :data="[
            { id: 1, name: '日常开支' },
            { id: 2, name: '员工提成' },
            { id: 3, name: '还款结算' },
            { id: 4, name: '实收现金' },
          ]"
          stripe
          :show-header="false"
          size="mini"
          border
        >
          <el-table-column prop="name" width="180"> </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div v-if="scope.row.id == 1">
                <span
                  v-for="(item, index) in totalStatisData.SpendItems"
                  :key="index"
                  >{{ item.Name }} : {{ item.Value }} ,
                </span>
              </div>
              <div v-if="scope.row.id == 2">
                <span
                  v-for="(item, index) in totalStatisData.RoyaItems"
                  :key="index"
                  >{{ item.Name }} : {{ item.Value }} ,
                </span>
              </div>
              <div v-if="scope.row.id == 3">
                <span>{{ totalStatisData.RepayTotal }}</span>

                <span
                  class="highlight"
                  v-if="totalStatisData.RepayItems != ''"
                  v-for="(item, index) in totalStatisData.RepayItems"
                  :key="index"
                  v-html="'( ' + item.Name + ' : ' + item.Value + ' )'"
                >
                </span>
              </div>
              <div v-if="scope.row.id == 4">
                {{ totalStatisData.CashBalance }}
                <!-- <span
                class=""
                v-for="(item, index) in totalStatisData.CardPayItems"
                :key="index"
                >{{ item.Name }} : {{ item.Value }} ,
              </span> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </table>

    <!-- end 统计表整合 -->
  </div>
</template>

<script>
import api from "@/api/report.js";
export default {
  data() {
    return {
      multipleDate: [], // 日期时间选择器
      tableData: [], // 表格数据
      tableArray: [], // 表格二维数组矩阵
      submitData: {
        // 提交数据
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        dept_guid: "", // 部门id
        subact: "query_data", //
      },
      loading: false, // 表格加载
      excelDisabled: false, // 导出禁用
      workSummaryData: {}, // 统计表的合计数据
      totalStatisData: {}, // 支付方式合计数据
      deptList: [], // 部门列表
    };
  },

  async mounted() {
    await this.initDateState()
    await this.initConditionData();
    await this.initPageData();
  },

  filters: {
    // 数字转字符串
    toFixed(value) {
      return value.toFixed(2);
    },
  },

  methods: {
    // 初始化部门数据
    async initConditionData() {
      try {
        let { data } = await api.categoryStatis({ subact: "init_data" });
        this.deptList = data.deptList;
      } catch (e) {}
    },

    // 初始化表格数据
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.categoryStatis(this.submitData);
        let { GategoryList, TotalStatisData, WorkSummaryData } = data;

        let arr = [];

        // 将源数据平级处理
        GategoryList.forEach((item, index) => {
          let payItems = item.PaymentList.filter((item2) => item2.PayMoney);
          let itemListLength = item.ItemList.length;

          item.ItemList.forEach((item3) => {
            let param = {
              ...item3,
              ...item,
              gategoryItemLength: itemListLength,
            };
            delete param.ItemList;
            delete param.PaymentList;
            arr.push(param);
          });

          if (!itemListLength) {
            arr.push({ ...item, gategoryItemLength: 1 });
          } else if (payItems.length || item.OpenKeepDonaMoney) {
            let param = {
              ...item,
              ...item.ItemList[0],
              ItemName: "",
              Name: "",
              gategoryItemLength: 1,
            };
            arr.push(param);
          }
        });

        this.tableData = arr;
        // 计算整个表格的二维矩阵数据
        this.twoDimensionalArray();

        this.workSummaryData = WorkSummaryData;
        this.totalStatisData = TotalStatisData;
        // console.log(this.totalStatisData.NotCardPayItems);
      } catch (e) {}
      this.loading = false;
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch(
        "onGetDateState"
      );
      this.multipleDate = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", {dayTime: event});
      this.initDateState()
    },

    // 查询按钮点击事件
    onSearch() {
      this.workSummaryData = [];
      this.totalStatisData = [];
      this.initPageData();
    },

    // 自定义二维数组（注意：这里是表格渲染数据的重中之重，如要修改请慎重）
    twoDimensionalArray() {
      let list = this.tableData; // 当前明细表数据
      let length = list.length; // 列表长度（表格一共多少行）

      let arr = []; // 用来存储数组矩阵

      // 初始化矩阵数组
      for (let x = 0; x < length; x++) {
        arr[x] = [];
        for (var y = 0; y < 2; y++) {
          arr[x][y] = {
            rowspan: 1,
            colspan: 1,
          };
        }
      }

      let count = 0; // 计数器（合并的大行里面的第几个小行）
      let zLenght = 8; // 常量（从1开始数，表格一共多少列，这个得自己数）

      // x就是arr数组的一级下标（当前表格第几行）
      for (var x = 0; x < length; x++) {
        let gategoryItemLength = list[x].gategoryItemLength || 1; // 当前项目的长度（同一个职位同一个员工，有多少条项目记录，也就是合并的大行里面有多少个小行）

        // y就是arr数组的二级下标（当前表格第几列）
        for (var y = 0; y < zLenght; y++) {
          if ((y > 5 || y < 1) && count == 0) {
            // 表格第1列、第5列之后的合并行处理
            arr[x][y] = {
              rowspan: gategoryItemLength, // 同职位同员工的数据需要占用几行
              colspan: 1, // 需要占用几列
            };
          } else if (y == 1 && !list[x].ItemName) {
            // 表格第二列，如果没有ItemName业绩类别，说明此行应渲染支付方式，合并后面的所有列
            arr[x][y] = {
              rowspan: 1,
              colspan: 7,
            };
          } else if (
            (y > 5 && count > 0) ||
            (y < 1 && count > 0) ||
            (y != 1 && !list[x].ItemName)
          ) {
            /* 表格第1列、第5列之后以及支付方式被合并时占用了多少行，那么下面被占用的行也是有矩阵数组表示的
              那么被占用的行就要输出 { rowspan: 0, colspan: 0,} 来表示空白行不渲染 */
            arr[x][y] = {
              rowspan: 0,
              colspan: 0,
            };
          } else {
            // 其他行正常就是 { rowspan: 1, colspan: 1,} 表示只占用1行1列，其实就是第2列至13列我们正常输出
            arr[x][y] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        }

        count++;

        if (gategoryItemLength == count) {
          // 当表格的每一大行的所有小行都循环完了，要重置小行计数
          count = 0;
        }
      }
      this.tableArray = arr;
    },

    // 自定义合并行回调函数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      return this.tableArray[rowIndex][columnIndex];
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "店内业绩统计表");
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-performance {
  .df-row {
    margin-top: 20px;
  }

  .custom-header {
    width: 100%;
    .notData {
      padding: 20px 0;
      text-align: center;
      font-size: 14px;
      color: #666;
    }
  }

  .big-table {
    tr:nth-child(2n + 1) {
      td:last-child {
        > .cell {
          padding: 0;
        }
      }
    }
  }

  .table-box {
    width: 100%;
    max-width: calc(100vw - (160px + 42px)) !important;

    table,
    > div {
      max-width: calc(100vw - (160px + 42px)) !important;
    }
  }

  .custom-table {
    tr > td:last-child {
      border-right: 0;
    }

    tr:first-child {
      > td:nth-child(6),
      > td:nth-child(7) {
        border-bottom: 0;
      }
    }

    tr:last-child {
      border-bottom: 0;
      > td {
        border-bottom: 0;
      }
    }
  }

  .el-table::before {
    content: "";
    height: 0 !important;
  }

  .df-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    td {
      padding: 10px;
    }
  }

  .table-tail {
    padding-bottom: 20px;
  }
}
.highlight {
  color: #0a8;
}
.lightskyblue {
  color: dodgerblue;
}
.nbsp {
  padding-left: 20px;
}

::v-deep .el-table__body tr:hover > .green {
  background-color: lightgreen !important;
}

::v-deep .el-table__row {
  .green {
    background-color: lightgreen !important;
    color: white !important;
  }
}
</style>
